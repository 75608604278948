import axios from 'axios';
import config from '../config';
import { userAuth } from 'utils/recoil/GlobalAtom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

export const AxiosAuth = () => {
  const auth = useRecoilValue(userAuth);
  const resetAuth = useResetRecoilState(userAuth);
  const navigate = useNavigate();

  const axiosInstance = axios.create({
    baseURL: `${config.apiUrl}/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`
    }
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status >= 401) {
        resetAuth();
        navigate('/');
      }
    }
  );

  return axiosInstance;
};
