// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../src/assets/Nunito-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  font-family: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  color: '#666666 !important';\n}\n\n@font-face {\n  font-family: 'Nunito';\n  src: local('Nunito'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n  color: '#666666 !important';\n}\n", "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAAA;EACE,oDAAoD;EACpD,2BAA2B;AAC7B;;AAEA;EACE,qBAAqB;EACrB,gFAAgF;EAChF,2BAA2B;AAC7B","sourcesContent":["body {\n  font-family: url('../src/assets/Nunito-Regular.ttf');\n  color: '#666666 !important';\n}\n\n@font-face {\n  font-family: 'Nunito';\n  src: local('Nunito'), url('../src/assets/Nunito-Regular.ttf') format('truetype');\n  color: '#666666 !important';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
