// project import
import dashboard from './dashboard';
import setting from './setting';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, setting]
};

export default menuItems;
