import { useMutation } from '@tanstack/react-query';
import { AxiosAuth } from 'utils/AxiosAuth';

export const useLogout = ({ onSuccess, onError }) => {
  const axiosInstance = AxiosAuth();
  return useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(`/oauth/logout`);

      return res;
    },
    onSuccess,
    onError
  });
};
