import { Navigate } from 'react-router-dom';
import { userAuth } from 'utils/recoil/GlobalAtom';
import { useRecoilValue } from 'recoil';

const PrivateRoutes = ({ children, option, ...rest }) => {
  const auth = useRecoilValue(userAuth);
  return Object.keys(auth).length === 0 ? <Navigate to="/" /> : children;
};

export default PrivateRoutes;
