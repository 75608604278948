// assets
import { HomeOutlined, BarChartOutlined, CreditCardOutlined, BookOutlined, FileDoneOutlined } from '@ant-design/icons';

// icons
const icons = {
  HomeOutlined,
  BarChartOutlined,
  CreditCardOutlined,
  BookOutlined,
  FileDoneOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  title: 'Navigation',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/main/dashboard',
      icon: icons.HomeOutlined,
      breadcrumbs: false,
      role: ['super admin', 'admin', 'owner', 'manager keuangan', 'direktur keuangan']
    },
    {
      id: 'transactions',
      title: 'Transactions',
      type: 'item',
      url: 'transactions',
      icon: icons.BarChartOutlined,
      breadcrumbs: false,
      role: ['super admin', 'owner', 'user', 'admin']
    },
    {
      id: 'transactions-reject',
      title: 'Transactions Reject',
      type: 'item',
      url: 'transactions-reject',
      icon: icons.BarChartOutlined,
      breadcrumbs: false,
      role: ['admin']
    },
    {
      id: 'verification',
      title: 'Verification',
      type: 'item',
      url: 'verification',
      icon: icons.FileDoneOutlined,
      breadcrumbs: false,
      role: ['super admin', 'verificator', 'owner', 'plan manager', 'direktur operasional']
    },
    {
      id: 'payment-request',
      title: 'Payment Request',
      type: 'item',
      url: 'payment-request',
      icon: icons.CreditCardOutlined,
      breadcrumbs: false,
      role: ['super admin', 'admin', 'user', 'owner', 'manager keuangan', 'direktur keuangan']
    },
    {
      id: 'recap-balance',
      title: 'Recap Balance',
      type: 'item',
      url: 'recap-balance',
      icon: icons.BookOutlined,
      breadcrumbs: false,
      role: ['super admin', 'admin', 'owner']
    }
  ]
};

export default dashboard;
