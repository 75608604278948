import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const userAuth = atom({
  key: 'userAuth',
  default: {},
  effects: [persistAtom]
});

export const userProfile = atom({
  key: 'userProfile',
  default: {},
  effects: [persistAtom]
});

export const paymentRequest = atom({
  key: 'paymentRequest',
  default: [],
  effects: [persistAtom]
});

export const menuList = atom({
  key: 'menuList',
  default: [],
  effects: [persistAtom]
});
