import { useNavigate } from 'react-router-dom';
import { menuList, userProfile } from 'utils/recoil/GlobalAtom';
import { useRecoilValue } from 'recoil';

const ProtectedRoutes = ({ children, option, ...rest }) => {
  const menuValue = useRecoilValue(menuList);
  const navigate = useNavigate();
  const authProfile = useRecoilValue(userProfile);
  const role = authProfile?.role.name.toLowerCase();
  // const role = 'verificator';
  const url = window.location.href;

  // Split the URL by '/' and get the last segment
  const segments = url.split('/');
  const lastSegment = segments[4];

  const findMenu = menuValue.find((value) => value?.id === lastSegment);

  return findMenu?.roles.indexOf(role) > -1 ? children : navigate('/main/access-page');
  // }, 1000);
};

export default ProtectedRoutes;
