// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { menuList } from 'utils/recoil/GlobalAtom';
import { useSetRecoilState } from 'recoil';
// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const setMenuList = useSetRecoilState(menuList);
  let arrMenu = [];
  // console.log(menuItem.items);
  menuItem.items.map((value) => {
    value.children.map((val) => {
      const params = {
        id: val.id,
        roles: val.role
      };

      arrMenu.push(params);
    });
  });
  setMenuList(arrMenu);

  const navGroups = menuItem.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
