import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import PrivateRoutes from 'utils/PrivateRoutes';
import ProtectedRoutes from 'utils/ProtectedRoutes';
// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const TransactionView = Loadable(lazy(() => import('pages/transactions/TransactionView')));
const TransactionForm = Loadable(lazy(() => import('pages/transactions/TransactionForm')));
const PaymentRequestView = Loadable(lazy(() => import('pages/payment-request/PaymentRequestView')));
const PaymentRequestForm = Loadable(lazy(() => import('pages/payment-request/PaymentRequestForm')));
const RecapBalanceView = Loadable(lazy(() => import('pages/recap-balance/RecapBalanceView')));
const RecapBalanceForm = Loadable(lazy(() => import('pages/recap-balance/RecapBalanceForm')));
const UsersView = Loadable(lazy(() => import('pages/users/UsersView')));
const BudgetView = Loadable(lazy(() => import('pages/budget/BudgetView')));
const CompanyView = Loadable(lazy(() => import('pages/company/CompanyListView')));
const BankAccountView = Loadable(lazy(() => import('pages/bank-account/BankAccountListView')));

const VerificationView = Loadable(lazy(() => import('pages/verification/VerificationListView')));
const VerificationForm = Loadable(lazy(() => import('pages/verification/VerificationForm')));
const TransactionFormPayment = Loadable(lazy(() => import('pages/transactions/TransactionFormPayment')));
const ClassificationView = Loadable(lazy(() => import('pages/classification/ClassificationListView')));
const NotFoundView = Loadable(lazy(() => import('pages/authentication/NotFound')));
const AccessPage = Loadable(lazy(() => import('pages/authentication/AccessPage')));
const TransactionRejectView = Loadable(lazy(() => import('pages/transactions-reject/TransactionRejectListView')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/main',
  element: (
    <PrivateRoutes>
      <MainLayout />
    </PrivateRoutes>
  ),
  children: [
    {
      path: '/main/dashboard',
      element: (
        <ProtectedRoutes>
          <DashboardDefault />
        </ProtectedRoutes>
      )
    },

    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'transactions',
      element: (
        <ProtectedRoutes>
          <TransactionView />
        </ProtectedRoutes>
      )
    },
    {
      path: 'transactions/new',
      element: (
        <ProtectedRoutes>
          <TransactionForm />
        </ProtectedRoutes>
      )
    },
    {
      path: 'transactions/:idTransaction/detail',
      element: (
        <ProtectedRoutes>
          <TransactionForm />
        </ProtectedRoutes>
      )
    },
    {
      path: 'transactions/payment',
      element: (
        <ProtectedRoutes>
          <TransactionFormPayment />
        </ProtectedRoutes>
      )
    },
    {
      path: 'payment-request',
      element: (
        <ProtectedRoutes>
          <PaymentRequestView />
        </ProtectedRoutes>
      )
    },
    {
      path: 'payment-request/:idPayment/detail',
      element: (
        <ProtectedRoutes>
          <PaymentRequestForm />
        </ProtectedRoutes>
      )
    },
    {
      path: 'payment-request/:idPayment/edit',
      element: (
        <ProtectedRoutes>
          <TransactionFormPayment />
        </ProtectedRoutes>
      )
    },
    {
      path: 'payment-request/new',
      element: (
        <ProtectedRoutes>
          <PaymentRequestForm />
        </ProtectedRoutes>
      )
    },
    {
      path: 'recap-balance',
      element: (
        <ProtectedRoutes>
          <RecapBalanceView />
        </ProtectedRoutes>
      )
    },
    {
      path: 'recap-balance/:idRecap/detail',
      element: (
        <ProtectedRoutes>
          <RecapBalanceForm />
        </ProtectedRoutes>
      )
    },
    {
      path: 'verification',
      element: (
        <ProtectedRoutes>
          <VerificationView />
        </ProtectedRoutes>
      )
    },
    {
      path: 'verification/:idVerification/detail',
      element: (
        <ProtectedRoutes>
          <VerificationForm />
        </ProtectedRoutes>
      )
    },
    {
      path: 'users',
      element: (
        <ProtectedRoutes>
          <UsersView />
        </ProtectedRoutes>
      )
    },
    {
      path: 'budget',
      element: (
        <ProtectedRoutes>
          <BudgetView />
        </ProtectedRoutes>
      )
    },
    {
      path: 'company',
      element: (
        <ProtectedRoutes>
          <CompanyView />
        </ProtectedRoutes>
      )
    },
    {
      path: 'bank-account',
      element: (
        <ProtectedRoutes>
          <BankAccountView />
        </ProtectedRoutes>
      )
    },
    {
      path: 'classification',
      element: (
        <ProtectedRoutes>
          <ClassificationView />
        </ProtectedRoutes>
      )
    },
    {
      path: 'transactions-reject',
      element: (
        <ProtectedRoutes>
          <TransactionRejectView />
        </ProtectedRoutes>
      )
    },
    {
      path: '*',
      element: <NotFoundView />
    },
    {
      path: 'access-page',
      element: <AccessPage />
    }
  ]
};

export default MainRoutes;
