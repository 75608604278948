// assets
import {
  ChromeOutlined,
  QuestionOutlined,
  UserOutlined,
  WalletOutlined,
  SettingOutlined,
  BankOutlined,
  HddOutlined
} from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
  UserOutlined,
  WalletOutlined,
  SettingOutlined,
  BankOutlined,
  HddOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const support = {
  id: 'setting',
  title: 'Setting',
  type: 'group',
  children: [
    {
      id: 'users',
      title: 'Users',
      type: 'item',
      url: 'users',
      icon: icons.UserOutlined,
      role: ['super admin']
    },
    {
      id: 'budget',
      title: 'Budget',
      type: 'item',
      url: 'budget',
      icon: icons.WalletOutlined,
      role: ['super admin']
    },
    {
      id: 'company',
      title: 'Company',
      type: 'item',
      url: 'company',
      icon: icons.HddOutlined,
      role: ['super admin']
    },
    {
      id: 'bank-account',
      title: 'Bank Account',
      type: 'item',
      url: 'bank-account',
      icon: icons.BankOutlined,
      role: ['super admin']
    },
    {
      id: 'classification',
      title: 'Classification',
      type: 'item',
      url: 'classification',
      icon: icons.SettingOutlined,
      role: ['super admin']
    }
  ]
};

export default support;
